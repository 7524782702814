import {
    FILTER_PLACEHOLDER_MATERIALS,
    GET_PLACEHOLDER_MATERIAL_DETAILS,
    GET_PLACEHOLDER_MATERIALS,
    GET_PLACEHOLDER_MATERIALS_BULK,
    SORT_PLACEHOLDER_MATERIALS,
} from '../actions';
import {EXPORT_MATERIALS_CONTRACTS_TO_CSV} from '../../material/actions'

import {API} from '@aws-amplify/api'
import config from '../../../aws-exports.js'

import {getFromServer, parseDynamoResult} from '../../global/middleware';
import {ERROR} from "../../global/actions";


let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

let questObject;
let bulkResult;
let bulkObjects;
let headers;
let materialsToExport;

async function getMaterialPlaceholderDetailsFromServer(action) {
    let questObject = {};
    questObject.params = {
        serial_number: action.data?.serial_number,
        business_partner: action.data?.business_partner,
        show_entitlements: true,
        show_parents: true,
        show_children: true
    }
    console.log("action", action);
    questObject.resource = 'placeholderMaterial';

    let getResult = await getFromServer(questObject);
    if (getResult.responseCode < 202 && getResult !== 0) {
        let result = getResult.results;
        let getDynamoResult;
        // Getting extra details from Dynamo
        questObject = {
            resource: 'serialNumber',
            params: { serial_number: action.data?.serial_number }
        };
        getDynamoResult = await getFromServer(questObject);
        if (getDynamoResult?.results?.length > 0) {
            result.serialNumberReference = parseDynamoResult(getDynamoResult.results[0]);
        };
        // Getting order details from Dynamo
        if (action.data?.order_id && action.data?.order_line) {
            // Replacing last two characters, as business logic requires them but they cannot be searched on
            let parsedOrderLine = action.data.order_line;
            if (!(parsedOrderLine?.endsWith("00") || parsedOrderLine?.endsWith("50"))) {
                parsedOrderLine = parsedOrderLine?.slice(0, -2) + "00";
            }
            questObject = {
                resource: 'order',
                params: { order_id: action.data.order_id, order_line: parsedOrderLine }
            };
            getDynamoResult = await getFromServer(questObject);
            if (getDynamoResult?.results?.length > 0) {
                result.order = getDynamoResult.results[0];
                // Displaying original order line
                result.order.order_line = action.data.order_line;
            };
        };
        return result;
    } else {
        throw new Error()
    };
};


export function materialPlaceholderMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type){
                case GET_PLACEHOLDER_MATERIAL_DETAILS:
                    try {
                        action.results = await getMaterialPlaceholderDetailsFromServer(action);
                    } catch (err) {
                        dispatch({ type: ERROR });
                    };
                    break;
                case FILTER_PLACEHOLDER_MATERIALS:
                    break;
                case SORT_PLACEHOLDER_MATERIALS:
                    break;
                case GET_PLACEHOLDER_MATERIALS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'placeholderMaterials';

                    bulkResult = await getFromServer(questObject);
                    action.results = bulkResult.results;
                    break;
                // TODO explore this call, why export material_contract
                case GET_PLACEHOLDER_MATERIALS_BULK:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.params.show_entitlements = true;
                    questObject.resource = 'placeholderMaterials';

                    bulkResult = await getFromServer(questObject);
                    bulkObjects = bulkResult.results;
                    headers = [
                        "bundle_master",
                        "bundle_reference",
                        "business_partner_id",
                        "comments",
                        "creation_date",
                        "creation_note",
                        "description",
                        "inactive_reason",
                        "modification_date",
                        "old_order_id",
                        "old_purchase_order",
                        "order_line",
                        "order_id",
                        "purchase_order",
                        "part_number",
                        "placeholder_id",
                        "serial_number",
                        "shipping_date",
                        "sn_type",
                        "sold_to",
                        "status",
                        "support_partner",
                        "type"
                    ];

                    materialsToExport = headers.join(",") + "\n";

                    for (let material of bulkObjects) {
                        let newMaterialArray = [
                            material?.bundle_master,
                            material?.bundle_reference,
                            material?.business_partner_id,
                            material?.comments,
                            material?.creation_date,
                            material?.creation_note,
                            material?.description,
                            material?.inactive_reason,
                            material?.modification_date,
                            material?.old_order_id,
                            material?.old_purchase_order,
                            material?.order?.order_line,
                            material?.order?.order_id,
                            material?.order?.purchase_order,
                            material?.part_number,
                            material?.placeholder_id,
                            material?.serial_number,
                            material?.shipping_date,
                            material?.sn_type,
                            material?.sold_to?.applicant_id,
                            material?.status,
                            material?.support_partner?.company_id,
                            material?.type
                        ];
                        materialsToExport = materialsToExport + newMaterialArray.join(",") + "\n";
                    };
                    dispatch({ type: EXPORT_MATERIALS_CONTRACTS_TO_CSV, data: { materials: bulkObjects, type: 'placeholder' } });
                    action.CSVMaterials = materialsToExport;
                    break;
                };
            return next(action);

        }
    }
}