import * as React from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import {extractAndDelete} from '../utils';
import HwsIn from "./tables/hwsIn";
import HwsOut from "./tables/hwsout";
import {Grid, Modal, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {callHwsSwap, cleanApiResponse, exportHwsSwapToCSV} from "../redux/dynamo_db/actions";
import { getContractDetails } from '../redux/contract/actions';
import Contracts from "./tables/contracts";
import MaterialSearch from "./tables/materialSearch";


export default function HwsSwapDetail() {
  const dispatch = useDispatch();
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const location = useLocation();
  let hwsIdentifier = location.pathname.split("/")[location.pathname.split("/").length - 1];
  // The parse/stringify cheap cloning trick is to create a local copy of the state object
  const hwsSnTrail = useSelector((state) => {
    let _hwsSnTrail = state?.hwsSnsTrail?.find(c => c.SK === hwsIdentifier);
    if (_hwsSnTrail) {
      return JSON.parse(JSON.stringify(_hwsSnTrail));
    } else return {}
  });
  const _apiResponse = useSelector(state => state.apiResponse);

  React.useEffect(() => {
    dispatch(cleanApiResponse());
  }, []);
  const handleExportToCsv = () => {
    const hwsSwapSns = {
      "serial_number_hws_in": hwsSnTrail?.serial_number_hws_in,
      "business_partner_hws_in": hwsSnTrail?.business_partner_hws_in,
      "serial_number_hws_out": selectedSerialNumber === hwsSnTrail?.serial_number_hws_out ? hwsSnTrail?.serial_number_hws_out : null ,
    };
    dispatch(exportHwsSwapToCSV(hwsSwapSns));
  };
  const handleHwsSwap = () => {
    const hwsSwapSns = {
      "serial_number_hws_in": hwsSnTrail?.serial_number_hws_in,
      "business_partner_hws_in": hwsSnTrail?.business_partner_hws_in,
      "serial_number_hws_out": selectedSerialNumber,
    };
    dispatch(callHwsSwap(hwsSwapSns));
  };

  const checkHwsSwap = () => {
    if(selectedSerialNumber === null) {
      setOpenModal(true);
    }
    else{
      setOpenConfirmModal(true);
    }
  };


  const hwsSnTrailClone = JSON.parse(JSON.stringify(hwsSnTrail))
  const hwsSnTrailInFields = [
    { name: 'Serial Number', key: 'serial_number_hws_in' },
    { name: 'Arrival date', key: 'hws_in_arrival_date' },
    { name: 'Order ID', key: 'hws_order_id' },
    { name: 'Base Order Line', key: 'hws_base_order_line' },
    { name: 'Part Number', key: 'part_number_hws_in' },
    { name: 'Applicant Id', key: 'applicant_id_hws_in' },
    { name: 'Business Partner CRD Id', key: 'business_partner_hws_in' },
    { name: 'Support Partner CRD Id', key: 'support_partner_hws_in' },
    { name: 'End Customer Id', key: 'end_customer_hws_in' },
    { name: 'Bundle reference', key: 'bundle_reference_hws_in' },
  ];

  const hwsSnTrailInDetails = hwsSnTrailInFields.map(({ name, key }) => ({
    name,
    value: extractAndDelete(hwsSnTrailClone, key) || ''
  }));

  return (<>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <HwsIn hwsSnTrailInDetails={hwsSnTrailInDetails}/>
      <div
      />
    </div>
    <div
      style={{marginBottom: '1%'}}
    />
    <Modal
      open={openConfirmModal}
      style={{
        textAlign: "center",
        paddingTop: "45vh"
      }}>
      <>
        <Typography
          style={{ color: 'white' }}
        >
          This will swap all entitlements and relationship from one serial number to the other, are you sure you want to continue?
        </Typography>
        <div style={{ paddingBottom: '1%' }} />
        <Grid container className='headercontainer'>
          <Grid item>
            <Button
              style={{
                color: 'white',
                background: '#6b469e'
              }}
              onClick={() => { handleHwsSwap(); setOpenConfirmModal(false) }}
            >Yes</Button>
          </Grid>
          <Grid item style={{ width: '7%' }} />
          <Grid item>
            <Button
              style={{
                color: 'white',
                background: '#6b469e'
              }}
              onClick={() => setOpenConfirmModal(false)}
            >No</Button>
          </Grid>
        </Grid>
      </>
    </Modal>
    <Modal
      open={openModal}
      style={{
        textAlign: "center",
        paddingTop: "45vh"
      }}>
      <>
        <Typography
          style={{ color: 'white' }}
        >
          One serial number MUST be selected.
        </Typography>
        <div style={{ paddingBottom: '1%' }} />
        <Grid container className='headercontainer'>
          <Grid item>
            <Button
              style={{
                color: 'white',
                background: '#6b469e'
              }}
              onClick={() => setOpenModal(false)}
            >Ok</Button>
          </Grid>
        </Grid>
      </>
    </Modal>
    <Contracts getContractDetails={getContractDetails} serialNumberHwsIn={hwsSnTrail?.serial_number_hws_in} businessPartnerHwsIn={hwsSnTrail?.business_partner_hws_in}/>
    <MaterialSearch hwsSnTrailInDetails={hwsSnTrail}/>
    <HwsOut hwsSnTrail={hwsSnTrail} selectedSerialNumber={selectedSerialNumber} setSelectedSerialNumber={setSelectedSerialNumber}/>
    <Grid
      container
      style={{
        justifyContent: 'center'
      }}
    >
      <Grid item>
        <Button variant="contained" onClick={handleExportToCsv} style={{ width: '15vw' }}>Export</Button>
      </Grid>
      <div style={{ paddingLeft: '0.2%' }} />
      <Grid item>
        <Button variant="contained" onClick={checkHwsSwap} style={{ width: '15vw' }}>Swap</Button>
      </Grid>
    </Grid>
    {/* Conditional rendering of Swap Result */}
    {_apiResponse && (
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Typography variant="h6">Swap Result: {_apiResponse}</Typography>
      </div>
    )}
  </>)
}
