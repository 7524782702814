import React from 'react';
import { Box, Chip, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';

export default function MultiSelector(props) {
  const [selectedItems, setSelectedItems] = React.useState(props.queryParams || []);
  const dispatch = useDispatch();
  const family = props?.family;
  const type = props?.type;
  const options = props?.options || []; // List of options to select from

  const handleChange = (event) => {
    const { value } = event.target;
    const newSelectedItems = typeof value === 'string' ? value.split(',') : value;

    // Find the newly added item
    const newlyAddedItem = newSelectedItems.find(item => !selectedItems.includes(item));

    if (newlyAddedItem) {
      const updatedItems = [...selectedItems, newlyAddedItem];
      setSelectedItems(updatedItems);
      dispatch(props.setQueryParams({ [family]: { [type]: newlyAddedItem } }));
    }
  };

  React.useEffect(() => {
    setSelectedItems(props.queryParams || []);
  }, [props.queryParams]);

  return (
    <React.Fragment>
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <FormControl style={{ flex: 8.5, height: '5%' }}>
          <InputLabel id="multi-select-label">{props.label}</InputLabel>
          <Select
            labelId="multi-select-label"
            id="multi-select"
            multiple
            value={selectedItems}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            disabled={props?.disabled ? props?.disabled : false}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div
        style={{
          flexDirection: 'row',
          width: '100%',
          overflow: 'auto',
          maxWidth: '100%',
          display: 'inline'
        }}
      >
        {selectedItems.map((chip, i) => (
          <Chip
            style={{
              marginRight: '1%',
              marginBottom: '0.1%'
            }}
            label={chip}
            onDelete={props.handleDelete(chip)}
            key={chip + i}
          />
        ))}
        {selectedItems.length ? (
          <Chip
            style={{
              marginRight: '1%',
              marginBottom: '0.1%',
              color: 'white',
              backgroundColor: '#6b469e'
            }}
            label='Clear'
            clickable={true}
            onClick={props.handleClear}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}
