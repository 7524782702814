import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import ContractServices from './contractServices';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GavelIcon from '@mui/icons-material/Gavel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Contracts(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const serialNumber = props?.serialNumberHwsIn;
    const businessPartner = props?.businessPartnerHwsIn
    const placeholderId = serialNumber + "#" + businessPartner;
    const placeholderMaterial = useSelector((state) => {
        let _placeholderMaterial = state?.placeholderMaterials?.find(m => m.placeholder_id === placeholderId);
        if (_placeholderMaterial) {
            return JSON.parse(JSON.stringify(_placeholderMaterial));
        } else return {}
    });
    const contracts = placeholderMaterial?.entitlements;
    const standardHardwareSupportContract = placeholderMaterial?.hardware_support_entitlement;
    const nameRows = [
        { name: 'Contract Reference' },
        { name: 'Creation Date' },
        { name: 'Description' },
        { name: 'Duration' },
        { name: 'End Date' },
        { name: 'Start Date' },
        { name: 'Entitlement End Date' },
        { name: 'Entitlement Start Date' },
        { name: 'Entitlement Status' },
        { name: 'Modification Date' },
        { name: 'Part Number' },
        { name: 'Quantity' },
        { name: 'Sold To' },
        { name: 'Status' },
        { name: 'Type' },
    ];

    const [expanded, setExpanded] = React.useState();

    function handleSelectContract(contractReference, type) {
        dispatch(props.getContractDetails(contractReference, type));
        navigate('/contracts/details/' + contractReference + '/' + type);
    };

    return (
        <React.Fragment>
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography style={{ width: '100%', }}>
                                <GavelIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Contracts (Source: Datalake)
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableRow>
                                    {nameRows?.map((row) => (
                                        <TableCell component="th" scope="row" align="center" style={{ fontWeight: "bolder" }}>
                                            {row.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {
                                    standardHardwareSupportContract &&
                                    (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            hover
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: '#e9dcfc'
                                            }}
                                            onClick={() => { handleSelectContract(standardHardwareSupportContract.contract_reference, standardHardwareSupportContract.type) }}
                                        >
                                            <TableCell align="center">{standardHardwareSupportContract.contract_reference}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.creation_date}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.description}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.duration}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.end_date}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.start_date}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.entitlement_end_date}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.entitlement_start_date}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.entitlement_status}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.modification_date}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.part_number}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.quantity}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.sold_to?.applicant_id}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.status}</TableCell>
                                            <TableCell align="center">{standardHardwareSupportContract.type}</TableCell>
                                        </TableRow>
                                    )
                                }

                                {contracts?.filter(contract => contract.contract_reference !== standardHardwareSupportContract?.contract_reference)?.map((contract) => {
                                    const valueRows = [
                                        { name: 'Contract Reference', value: contract?.contract_reference },
                                        { name: 'Creation Date', value: contract?.creation_date },
                                        { name: 'Description', value: contract?.description },
                                        { name: 'Duration', value: contract?.duration },
                                        { name: 'End Date', value: contract?.end_date },
                                        { name: 'Start Date', value: contract?.start_date },
                                        { name: 'Entitlement End Date', value: contract?.entitlement_end_date },
                                        { name: 'Entitlement Start Date', value: contract?.entitlement_start_date },
                                        { name: 'Entitlement Status', value: contract?.entitlement_status },
                                        { name: 'Modification Date', value: contract?.modification_date },
                                        { name: 'Part Number', value: contract?.part_number },
                                        { name: 'Quantity', value: contract?.quantity },
                                        { name: 'Sold To', value: contract?.sold_to?.applicant_id },
                                        { name: 'Status', value: contract?.status },
                                        { name: 'Type', value: contract?.type },
                                    ];
                                    return (
                                        <>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                hover
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => { handleSelectContract(contract.contract_reference, contract.type) }}

                                            >

                                                {
                                                    valueRows?.map((row, i) => {
                                                        return (

                                                            <TableCell align="center" style={{ position: 'relative' }}>
                                                                {i === 0 &&
                                                                    <Tooltip title='Show/hide services' placement="top">
                                                                        <IconButton
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: 0,
                                                                                bottom: 15
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (expanded === contract.contract_reference) {
                                                                                    setExpanded();
                                                                                } else {
                                                                                    setExpanded(contract.contract_reference);
                                                                                }
                                                                                e.stopPropagation();
                                                                            }}
                                                                        >
                                                                            {expanded === contract.contract_reference && <KeyboardArrowDownIcon />}
                                                                            {expanded !== contract.contract_reference && <KeyboardArrowRightIcon />}
                                                                        </IconButton >
                                                                    </Tooltip>
                                                                }
                                                                {row.value}
                                                            </TableCell>

                                                        )
                                                    })
                                                }

                                            </TableRow>
                                            <Accordion
                                                expanded={expanded === contract?.contract_reference}
                                            >
                                                <AccordionSummary style={{ display: "none" }} />
                                                <AccordionDetails>
                                                    <ContractServices entitlement={contract} />
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    )
                                })}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment>
    );

}