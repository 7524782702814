import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getMaterialDetails} from "../../redux/material/actions";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {addHwsSnsTrailOut} from "../../redux/dynamo_db/actions";

export default function MaterialSearch (props ) {
  const [serialNumber, setSerialNumber] = useState('');
  const [tempSn, setTempSn] = useState('');
  const [result, setResult] = useState('');
  const [searchCalled, setSearchCalled] = useState(false);
  const dispatch = useDispatch();
  const hwsSnTrailInDetails = props?.hwsSnTrailInDetails;
  const handleSearch = () => {
    setSerialNumber(tempSn);
    dispatch(getMaterialDetails(tempSn));
    setSearchCalled(true);
  };

  const searchMaterial = useSelector(state => {
    return state?.materials?.find(m => m.serial_number === serialNumber);
  });

  useEffect(() => {
    if (searchMaterial) {
      if (searchMaterial.part_number === hwsSnTrailInDetails?.part_number_hws_in) {
        if (searchMaterial.business_partner?.company_id === hwsSnTrailInDetails?.business_partner_hws_in) {
          if (searchMaterial.entitlements && searchMaterial.entitlements.length > 0) {
            const validPrefixes = ["DOA", "CpltServ", "StandardHWS", "EPP"];
            const isValid = searchMaterial.entitlements.every(entitlement =>
              validPrefixes.some(prefix => entitlement.contract_reference.startsWith(prefix)) ||
              entitlement.entitlement_status !== 'active'
            );
            if (isValid) {
              dispatch(addHwsSnsTrailOut({
                "serial_number_hws_out": searchMaterial?.serial_number,
                "hws_order_id": searchMaterial?.order?.order_id,
                "order_line_hws_out": searchMaterial?.order?.order_line,
                "hws_out_departure_date": searchMaterial?.modification_date,
                "part_number_hws_out": searchMaterial?.part_number,
                "applicant_id_hws_out": searchMaterial?.sold_to?.applicant_id,
                "business_partner_hws_out": searchMaterial?.business_partner?.company_id,
                "support_partner_hws_out": searchMaterial?.support_partner?.company_id,
                "end_customer_hws_out": searchMaterial?.end_user?.company_id,
                "bundle_reference_hws_out": searchMaterial?.serialNumberReference?.bundle_reference,
                "local_placeholder_id" : hwsSnTrailInDetails.serial_number_hws_in + "#" + hwsSnTrailInDetails?.business_partner_hws_in
              }));
              setResult('Material added to the list.');
            } else {
              setResult('This serial number is already in use and entitled with an active contract.');
            }
          } else {
            setResult('Material added to the list.');

          }
        } else {
          setResult('This serial number does not belong to the same BP as the failed SN.');
        }
      } else {
        setResult('This serial number is not compatible with the failed SN.');
      }
    } else {
      setResult('Material not found.');
    }
  }, [searchMaterial]);

  return (
    < React.Fragment >
      <div style={{ width: '100%' }}>
        <TableContainer component={Paper} >
          <Accordion
            defaultExpanded={true}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#6b469e",
                color: "white",
                height: '3vh'
              }}
              sx={{
                "&.Mui-expanded": {
                  minHeight: 0
                },
                "&.MuiAccordionSummary-root": {
                  minHeight: 0
                },
              }}
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            >
              <Typography
                style={{
                  width: '100%'
                }}
              >
                <ReceiptIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                Replacement candidate
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField id="outlined-basic" label="Enter serial number" variant="outlined"
                         onChange={(e) => setTempSn(e.target.value)}
                         style={{
                           flex: 8.5,
                           height: '5%'
                         }}
                         value={tempSn}
              />
              <Grid item>
                <Button variant="contained" onClick={handleSearch} style={{ width: '15vw' }}>Search</Button>
              </Grid>
              {searchCalled && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  <Typography variant="h6">{result}</Typography>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </TableContainer>
      </div>
    </React.Fragment >
  );
};

